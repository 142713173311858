<template>
  <!-- 联系我们 -->
  <div class="main">
    <!-- 标题 -->
    <div class="Box">
      <span class="Chtitle">{{ joinList.name }}</span>
      <span>{{ joinList.time }}</span>
    </div>
    <div class="content">
      <span v-html="joinList.details" />
    </div>
  </div>
</template>

<script>
import { join } from '@/api/about';

export default {
  name: 'JoinInIndex',
  data() {
    return {
      joinList: {},
    };
  },
  async created() {
    await this.join();
  },
  methods: {
    async join() {
      try {
        const params = {
          id: this.id,
        };

        const res = await join(params);
        this.joinList = res.data[0];
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.main {
  width: 1200px;
  margin: 0 auto;
  .Chtitle {
    color: #333;
  }
  .content {
    margin-top: 15px;
    width: 1200px;
    padding: 50px;
    border-top: 0.1px solid #eee;
    border-bottom: 0.1px solid #eee;
  }
  
}
</style>
